<template>
  <div class="home-page">
    <client-only>
      <IntroJs v-if="$auth.loggedIn" />
    </client-only>
    <HomeHero
      :content="heroSection"
    />
    <div class="home-page__content-container">
      <div
        v-if="topCategories && topCategories.length > 0"
        class="home-page__content-container__top-category-list"
      >
        <CategoryTile
          v-for="(category, index) in topCategories"
          :key="category.id + '_' + index"
          :category="category"
          :path="rootCategoryUrl"
          :type="'medium'"
        />
      </div>
      <div
        v-if="contentAreaBoxes && contentAreaBoxes.length > 0"
        class="home-page__content-container__free-content-list"
      >
        <CardTile
          v-for="(content, index) in contentAreaBoxes"
          :key="index"
          :content="content"
        />
      </div>
      <div
        v-if="topCategories && topCategories.length > 0"
        class="home-page__content-container__show-more"
      >
        <nuxt-link
          :to="localePath(rootCategoryUrl)"
          class="k-button k-button-md k-button-solid k-button-solid-primary"
        >
          <span>
            {{ $tv('showAllProducts', 'home') }}
          </span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryTile from '~/components/category/CategoryTile'
import CardTile from '~/components/home/CardTile'
import HomeHero from '~/components/home/HomeHero'
import {useStartPageService} from '@/composables/start_page_service'
import {CATEGORIES} from '~/assets/js/constants'
import {useCategoryDesignService} from '@/composables/category_design_service'
import IntroJs from '~/components/intro/IntroJs'
import useI18nExtended from '~/composables/usei18nExtended'

export default {
  components: {HomeHero, CardTile, CategoryTile, IntroJs},
  name: 'Home',
  async setup() {
    const {getStartPage} = useStartPageService()
    const i18n = useI18nExtended()
    const nuxtApp = useNuxtApp()
    const {getCategoryContentsByCategoryName, getTopCategories} = useCategoryDesignService()
    let startPage =  nuxtApp.payload?.data[`start-pages_${i18n.locale.value}_[{"field":"LoggedIn","operator":"eq","value":${nuxtApp.$auth.loggedIn}}]_false`]
    startPage = startPage?.find((ca) => ca.region === null || ca.region.RegionShortName === nuxtApp.$globalization.getRegion())
    let topCategories = nuxtApp.payload?.data[`categories_null_0_8_sortIndex_false_[{"field":"IsTopCategory","operator":"eq","value":true},{"field":"regions.RegionShortName","operator":"eq","value":"${nuxtApp.$globalization.getRegion()}"}]_false`]
    if (!startPage) {
      startPage = await getStartPage(i18n.locale.value, nuxtApp.$globalization.getRegion(), nuxtApp.$auth.loggedIn)
    }
    if (!topCategories) {
      topCategories = await getTopCategories()
    }
    const technicalNames = topCategories?.map(t => t.TechnicalName)
    const quotedTechnicalNames = technicalNames.map(name => `"${name}"`)
    const technicalNamesString = quotedTechnicalNames.join(',')
    let categoryContents = nuxtApp.payload?.data[`category-contents_${i18n.locale.value}_0_-1_null_null_{"field":"Category.TechnicalName","operator":"in","value":[${technicalNamesString}]}_false`]
    if (technicalNames.length > 0) {
      if (!categoryContents) {
        categoryContents = await getCategoryContentsByCategoryName(technicalNames, i18n.locale.value)
      }
      if (categoryContents && categoryContents.length >= 1) {
        topCategories.forEach(topCat => {
          const catCont = categoryContents.find(cc => cc.Category.TechnicalName === topCat.TechnicalName)
          if (!catCont) {
            topCat.ShortDescription = i18n.tv(topCat.TechnicalName, 'categories')
          } else {
            topCat.ShortDescription = catCont.ShortDescription
          }
        })
      }
    }
    let heroSection = null
    let contentAreaBoxes = []
    if (startPage) {
      heroSection = {
        Image: startPage.TopImage,
        Header: startPage.Headline,
        Subline: startPage.Subline,
        Link: startPage.Link,
        TextAppearance: startPage.TextAppearance,
        FSTwave: startPage.FSTwave,
        LoggedIn: startPage.LoggedIn,
        ButtonLabel: startPage.ButtonLabel
      }
      if (nuxtApp.$auth.loggedIn) {
        heroSection.Header = startPage.Headline?.replace('{name}', nuxtApp.$auth?.user?.name)
        heroSection.Subline = startPage.Subline?.replace('{name}', nuxtApp.$auth?.user?.name)
      }
      contentAreaBoxes = [].concat(startPage.ContentAreaBoxTop ?? [], startPage.ContentAreaBoxBottom ?? [])
    }
    return {startPage, contentAreaBoxes, heroSection, topCategories}
  },
  methods: {
  },
  data() {
    return {
      rootCategoryUrl: CATEGORIES.RootCategoriesUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  background-color: $color__content--background__third;

  &__content-container {
    display: flex;
    flex-flow: row wrap;
    padding: $default-margin-padding-2x;
    background-color: $color__content--background__third;

    @include breakpoint-down($md) {
      padding: 1.875rem $default-margin-padding-width 3.25rem;
    }

    $gap: 1.25rem;

    &__top-category-list {
      display: flex;
      flex-wrap: wrap;
      flex: 2 1 66%;
      order: 1;
      margin: -#{$gap} 0 0 -#{$gap};
      width: calc(100% + #{$gap});

      > .category-tile {
        display: inline-block;
        margin: #{$gap} 0 0 #{$gap};
      }

      + .home-page__content-container__free-content-list {
        @include breakpoint-up($xl) {
          justify-content: flex-end;
          margin-top: -#{$gap};
        }
      }
    }

    &__free-content-list {
      margin-left: -#{$gap};
      display: flex;
      flex: 1 1 33%;
      flex-wrap: wrap;
      order: 5;
    }

    &__show-more {
      flex-basis: 100%;
      margin-top: 1.5rem;
      margin-bottom: 2.0625rem;
      display: flex;
      justify-content: flex-end;
      order: 10;
      font-weight: $base-text-font-weight-light;

      @include breakpoint-down($xl) {
        flex: 0 0 auto;
        order: 3;
        justify-content: flex-end;
        margin-top: 1.875rem;
        margin-bottom: 1.25rem;
      }

      > a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include breakpoint-down($xl) {
      flex-direction: column;

      &__top-category-list,
      &__free-content-list {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
